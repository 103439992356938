<template>
    <!-- 考勤记录 -->
    <div class="check-record">
        <div class="recordCon">
            <div class="recordSearch">
                <div class="titleSearch">
                    <div class="titleS">
                        <el-select class="gateSty" v-model="deptId" placeholder="所属组织" clearable>
                            <el-option v-for="(item, index) in gateData" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <div class="timeSty">
                            <el-date-picker @change="timeChange" v-model="time" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="titleS">
                        <el-input @keyup.enter.native="searchClick" v-model="input" placeholder="姓名/身份证号码/手机号" clearable></el-input>
                        <el-button @click="searchClick" class="titleButton" type="primary" icon="el-icon-search">搜索</el-button>
                        <el-button @click="exportClick" class="titleButton" icon="el-icon-download" :loading="exportLoad">导出
                            <a title="data"></a>
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="recordTableC">
                <div class="recordTable">
                    <el-table v-loading="loading" :data="tableData" style="width: 100%" height="100%">
                        <el-table-column prop="createdAt" label="打卡时间" min-width="160">
                        </el-table-column>
                        <el-table-column prop="deptName" label="所属组织" min-width="240">
                        </el-table-column>
                        <el-table-column prop="name" label="姓名" min-width="90">
                        </el-table-column>
                        <el-table-column prop="cardNo" label="身份证号码" min-width="140">
                        </el-table-column>
                        <el-table-column prop="phone" label="电话号码" min-width="120">
                        </el-table-column>
                    </el-table>
                </div>
                <el-pagination :current-page="currentPage" :page-size="pagingData.pageSize || 0" @current-change="handleCurrentChange" background layout="total, prev, pager, next" :total="pagingData.totalRecords || 0">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import dayjs from 'dayjs'

    export default {
        name: 'check-record',
        components: {},
        data() {
            return {
                loading: false,
                // 导出 loading
                exportLoad: false,
                time: [],
                // 所属组织id
                deptId: null,
                // 获取到的 所属组织列表
                gateData: [],
                input: '',
                // 表格
                tableData: [],
                // 考勤记录-拉取到的数据
                pagingData: {
                    // page: {},
                },
                // 当前页数
                currentPage: 1,
                //列表-开始位置
                start: 1,
                // 开始时间
                startTime: '',
                // 结束时间
                endTime: '',
                // 权限
                isDis: null,
            }
        },
        created() {
            // 不同权限 被访问到的页面
            let userInfo = sessionStorage.getItem('userInfo')
            this.isDis = Number(userInfo)

            // 时间
            this.myTime()
            // 考勤记录
            this.getPage()
            // 组织管理 列表
            this.equipmentList()
        },
        methods: {
            // 组织管理 列表
            equipmentList() {
                this.$instance
                    .get('/v1/attendance/list', {
                        params: {
                            pageSize: 10000,
                        },
                    })
                    .then(res => {
                        if (res.data.status === 200) {
                            this.gateData = res.data.data.elements
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
            // 时间
            myTime() {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                // 开始时间
                this.startTime = dayjs(start)
                    .startOf('day')
                    .format('YYYY/MM/DD HH:mm:ss')
                // 结束时间
                this.endTime = dayjs(end)
                    .endOf('day')
                    .format('YYYY/MM/DD HH:mm:ss')
                this.time = [this.startTime, this.endTime]
            },
            // 时间-用户确认选定的值时触发
            timeChange() {
                if (this.time !== null) {
                    // 开始时间
                    let startTime = dayjs(this.time[0]).format(
                        'YYYY/MM/DD HH:mm:ss'
                    )
                    // 结束时间
                    let endTime = dayjs(this.time[1]).format('YYYY/MM/DD HH:mm:ss')
                    this.time = [startTime, endTime]
                }
            },
            // 点击搜索
            searchClick() {
                this.currentPage = 1
                // 搜索并拉取列表
                this.getPage()
            },
            //  @param {String} href
            //  @param {String} filename
            imatateDownloadByA(href, filename) {
                const a = document.createElement('a')
                a.download = filename
                a.style.display = 'none'
                a.href = href
                document.body.appendChild(a)
                a.click()
                a.remove()
                window.URL.revokeObjectURL(href)
            },
            // 点击导出
            exportClick() {
                this.exportLoad = true
                this.color()
                this.$instance
                    .get('/v1/staff/logList/download', {
                        params: {
                            pageSize: 10000,
                            // 组织ID
                            deptId: this.deptId ? this.deptId : 0,
                            // 开始时间
                            startTime: this.time[0],
                            // 结束时间
                            endTime: this.time[1],
                            // 搜索关键字
                            searchKey: this.input,
                        },
                        responseType: 'arraybuffer',
                    })
                    .then(res => {
                        // console.log('res1: ', res)
                        // const blob = new Blob([res.data])
                        const blob = new Blob([res.data], {
                            type:
                                // 'application/vnd.ms-excel;charset=utf-8',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        })
                        // IE
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob, 'data')
                        } else {
                            this.imatateDownloadByA(
                                window.URL.createObjectURL(blob),
                                '考勤记录data'
                            )
                        }
                        this.exportLoad = false
                    })
                    .catch(error => {
                        console.log('error: ', error)
                        this.exportLoad = false
                    })
            },
            // 点击分页-当前页
            handleCurrentChange(val) {
                this.currentPage = val
                // 搜索并拉取列表
                this.getPage()
            },
            // 传值 转化
            color() {
                // 如果没有时间选择 默认最近一个月
                if (!this.time) {
                    this.time = [this.startTime, this.endTime]
                }
                this.time[0] = this.time[0].replace(/\\|\//g, '-')
                this.time[1] = this.time[1].replace(/\\|\//g, '-')
            },
            // 考勤记录
            getPage() {
                this.loading = true
                this.color()

                this.$instance
                    .get('/v1/staff/logList', {
                        params: {
                            pageNum: this.currentPage - this.start,
                            pageSize: 10,
                            // 组织ID
                            deptId: this.deptId ? this.deptId : 0,
                            // 开始时间
                            startTime: this.time[0],
                            // 结束时间
                            endTime: this.time[1],
                            // 搜索关键字
                            searchKey: this.input,
                        },
                    })
                    .then(res => {
                        if (res.data.status === 200) {
                            this.loading = false
                            this.pagingData = res.data.data
                            this.tableData = this.pagingData.elements
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .check-record {
        width: calc(100% - 40px);
        margin: 20px;
        .recordSta {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            margin-bottom: 20px;
            padding: 0 120px;
            box-sizing: border-box;
            .recordStaCon {
                display: flex;
                align-items: center;
                .recordStaNum {
                    color: #333;
                    font-size: 20px;
                    font-weight: bold;
                }
                .recordStaNum1 {
                    color: rgb(0, 128, 0);
                }
                .recordStaNum2 {
                    color: rgb(240, 183, 37);
                }
                .recordStaNum3 {
                    color: rgb(222, 39, 39);
                }
            }
        }
        & ::v-deep .recordCon {
            width: 100%;
            height: 100%;
            background-color: #fff;
            // 搜索
            .recordSearch {
                width: 100%;
                padding: 16px 20px 6px;
                box-sizing: border-box;
                border-bottom: 1px #f0f0f0 solid;
                .titleSearch {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    .titleS {
                        display: flex;
                        margin-bottom: 14px;
                        margin-right: 10px;
                        .titleSear {
                            position: relative;
                            .el-select {
                                .el-input__inner {
                                    padding-right: 46px;
                                }
                                .el-input__suffix {
                                    margin-right: 18px;
                                    .el-icon-arrow-up {
                                        display: none;
                                    }
                                }
                            }
                            .iconSearch {
                                width: 24px;
                                position: absolute;
                                top: 9px;
                                right: 14px;
                                color: rgb(182, 182, 182);
                                font-size: 14px;
                                background-color: #fff;
                                text-align: center;
                                z-index: 2;
                                cursor: pointer;
                            }
                        }
                        .timeSty {
                            margin-left: 10px;
                            .el-date-editor {
                                width: 360px;
                            }
                            .el-input__icon,
                            .el-range-separator {
                                height: unset;
                            }
                        }
                        .el-input__inner {
                            height: 32px;
                            line-height: 32px;
                        }
                        .el-button--primary {
                            height: 32px;
                            border: unset;
                        }
                        .titleButton {
                            line-height: 30px;
                            padding: 0 12px;
                        }
                        .el-select > .el-input,
                        .el-input {
                            margin-right: 10px;
                        }
                        .codeColor > .el-input {
                            margin-right: unset;
                        }
                        .el-select > .el-input {
                            width: 180px;
                        }
                        .gateSty > .el-input {
                            width: 110px;
                        }
                        .codeColor > .el-input {
                            width: 120px;
                        }
                        .el-input {
                            width: 200px;
                            .el-input__suffix {
                                i {
                                    line-height: 32px;
                                }
                            }
                        }
                    }
                }
            }
            .recordTableC {
                width: calc(100% - 40px);
                height: calc(100% - 176px);
                margin: 20px;
                // 表格
                .recordTable {
                    height: 100%;
                    border: 1px #f0f0f0 solid;
                    overflow: auto;
                    .el-table td,
                    .el-table th {
                        height: 49px;
                        padding: 4px 0;
                        .cell {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
</style>
